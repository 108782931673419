import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import {
  blogDate,
  blogLink,
} from "./blog.module.css";

const BlogPage = ({ data }) => {
    return (
        <Layout pageTitle="My Blog Posts">
            <div>
                <h4>{data.allMarkdownRemark.totalCount} Posts</h4>
                {data.allMarkdownRemark.edges.map(({ node }) => {
                  let commentMessage = "No Commnets";
                  if (node.frontmatter.comments) {
                    const commentCount = node.frontmatter.comments.length;
                    if (commentCount === 1) {
                      commentMessage = "1 Comment";
                    } else {
                      commentMessage = commentCount + " Comments";
                    }
                    console.log("comments", node.frontmatter.comments);
                  }
                  return (
                    <div key={node.id}>
                        <Link className={blogLink} to={node.fields.slug}>
                            <h3>{node.frontmatter.title}{" "}
                            <span className={blogDate}>- {node.frontmatter.date}</span>
                            </h3>
                        </Link>

                        <h5>{commentMessage}</h5>
                        <p>{node.excerpt}</p>
                        <hr />
                    </div>
                )})}
            </div>
        </Layout>
    );
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            comments {
              name
              date(formatString: "DD MMMM, YYYY")
              comment
            }
          }
          fields {
              slug
          }
          excerpt
        }
      }
    }
  } 
`;

export default BlogPage;
