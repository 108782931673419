import * as React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { 
    container,
    heading,
    navLinks,
    navLinkItem,
    navLinkText,
    siteTitle,
    siteFooter
 } from './layout.module.css';

const Layout = ({ pageTitle, children }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata{
                    title
                }
            }
        }
    `);

    const year = new Date().getFullYear();

    return (
        <main className={container}>
            <title>{pageTitle} | {data.site.siteMetadata.title}</title>
            <p className={siteTitle}>{data.site.siteMetadata.title}</p>
            <nav>
                <ul className={navLinks}>
                    <li className={navLinkItem}><Link to="/" className={navLinkText}>Home</Link></li>
                    <li className={navLinkItem}><Link to="/blog" className={navLinkText}>Blog</Link></li>
                </ul>
            </nav>
            <h1 className={heading}>{pageTitle}</h1>
            {children}
            <footer className={siteFooter}>
                <p>&copy; 2014 - {year} - A <a href="https://spatacoli.com">Spatacoli</a> Web Site.</p>
            </footer>
        </main>
    )
}

export default Layout;
